.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: inherit;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  transition:
    opacity 0.3s,
    background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-top:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.back-to-top:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

@media (max-width: 600px) {
  .back-to-top {
    bottom: 15px;
    left: 15px;
    padding: 8px;
    font-size: 16px;
  }
}
