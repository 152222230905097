.banner-container {
  position: relative;
  width: 100%;
  margin: 8px auto 0;
  max-width: 1920px;
}

.banner-slide-container {
  position: relative;
  overflow: hidden;
}

.banner-slide-wrapper {
  display: flex;
  transition: transform 500ms ease-out;
}

.banner-slide {
  min-width: 100%;
}

.banner-image {
  width: 100%;
  height: 520px;
  object-fit: cover;
}

.banner-image.mobile {
  height: 400px;
}

.banner-arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: rgba(75, 75, 75, 0.6);
  color: white;
  box-shadow: 0 2px 4px rgba(75, 75, 75, 0.6);
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.banner-arrow-button:hover {
  background: rgba(75, 75, 75, 0.8);
}

.banner-arrow-button.prev {
  left: 10px;
}

.banner-arrow-button.next {
  right: 10px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
}

.banner-dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.banner-dots ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.banner-dots li {
  display: inline-block;
}

.dot-button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot-button:hover {
  background: rgba(255, 255, 255, 0.75);
}

.dot-button.active {
  background: white;
}