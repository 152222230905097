.slider-container {
  max-width: 1200px;
  margin: 0 auto 4rem;
  padding: 0 1rem;
}

.slider-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #1b2f4c;
  margin-bottom: 1.5rem;
}

.slider-divider {
  width: 8rem;
  height: 4px;
  background-color: #1b2f4c;
  margin: 0 auto 3rem;
  border-radius: 9999px;
}

.slider-wrapper {
  position: relative;
}

.slider-track-wrapper {
  overflow: hidden;
  padding-bottom: 10px;
}

.slider-track {
  display: grid;
  transition: transform 0.5s ease-out;
}

.slider-item {
  padding: 0 0.75rem;
}

.card {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: translateY(0);
  min-height: 360px;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.card-image-container {
  position: relative;
  height: 12rem;
  background-color: #f3f4f6;
}

.card-image {
  width: 100%;
  height: 140px;
  object-fit: cover;
}

.card-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1b2f4c;
  margin-bottom: 0.5rem;
}

.card-date {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 1rem;
}

.card-button {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #1074e2;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.card-button:hover {
  background-color: #0d5fc7;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 10;
}

.slider-arrow:hover {
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.slider-arrow:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.slider-arrow.prev {
  left: 0.5rem;
}

.slider-arrow.next {
  right: 0.5rem;
}

.slider-arrow svg {
  width: 1.5rem;
  height: 1.5rem;
  stroke: #1b2f4c;
  stroke-width: 2.5;
  transition: transform 0.3s ease;
}

.slider-arrow.prev:hover svg {
  transform: translateX(-2px);
}

.slider-arrow.next:hover svg {
  transform: translateX(2px);
}

.slider-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.slider-dot {
  width: 0.625rem;
  height: 0.625rem;
  background-color: rgba(27, 47, 76, 0.2);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
}

.slider-dot:hover {
  background-color: rgba(27, 47, 76, 0.4);
}

.slider-dot.active {
  width: 2rem;
  background-color: #1074e2;
}

.slider-loading {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 0 0.75rem;
}

.slider-skeleton {
  height: 400px;
  background-color: #f3f4f6;
  border-radius: 0.75rem;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
}

@media (max-width: 1024px) {
  .slider-loading {
    grid-template-columns: repeat(2, 1fr);
  }

  .slider-track {
    grid-template-columns: repeat(10, 100%);
  }
}

@media (max-width: 768px) {
  .slider-loading {
    grid-template-columns: 1fr;
  }

  .slider-title {
    font-size: 1.5rem;
  }

  .slider-divider {
    width: 6rem;
  }

  .slider-track {
    grid-template-columns: repeat(10, 100%);
  }
}
